<template>
  <div class="page-container">
    <div class="banner-content">
      <div class="banner">
        <!--<img src="../../assets/banner/zixun_bg_banner.min.jpg" alt="">-->
        <img :src="bannerUrl" alt>
      </div>
      <div class="page-title-content">
        <div class="page-title">
          <div class="first-title">最新资讯</div>
          <div class="two-title">一手掌握</div>
        </div>
      </div>
    </div>
    <div class="page-content-div">
      <div class="page-content">
        <div v-if="isLoading" style="text-align: center;line-height: 200px;font-size: 30px">
          <i class="el-icon-loading"></i>
        </div>
        <div class="consultation-content">
          <!-- <router-link v-for="(item, index) in dataList" :to="{path: '/latest-consultation/consultation-info', query: {id: item.id}}">

          </router-link>-->
          <div class="news-info" v-for="(item, index) in dataList" :key="index">
            <div class="img-content">
              <img :src="item.imgUrl" alt="资讯图片">
            </div>
            <p class="news-title">{{item.title}}</p>
            <small>{{item.newsTime}}</small>
            <div class="option-btn">
              <router-link
                :to="{path: '/latest-consultation/consultation-info', query: {id: item.id}}"
              >
                <el-button type="primary" plain>查看详情</el-button>
              </router-link>
              <router-link
                :to="{path: '/application-area', query: {areaType: item.applicationRange}}"
              >
                <el-button type="primary" plain>
                  领域
                  <span v-if="item.applicationRange">: {{typeListObj[item.applicationRange]}}</span>
                </el-button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="pagination-content" v-if="dataList.length">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :page-size="pageModal.pageSize"
            :current-page.sync="pageModal.pageNum"
            :total="pageModal.totalCount"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      dataList: [],
      pageModal: {
        pageNum: 1,
        totalCount: 0,
        pageSize: 6
      },
      isLoading: false,
      typeListObj: {},
      bannerUrl: ""
    };
  },
  mounted() {
    const banners = JSON.parse(localStorage.getItem("banners"));
    banners[7]
      ? (this.bannerUrl = banners[7]["imgUrl"])
      : (this.bannerUrl = this.$url.backImg[7]);
    this.typeListObj = this.$url.typeListObj;
    this.getList();
  },
  methods: {
    getList() {
      this.isLoading = true;
      this.dataList = [];
      const url = this.$url.getBackendApiUrl(
        `/api/news/list?&pageSize=${this.pageModal.pageSize}&pageNum=${
          this.pageModal.pageNum
        }`
      );
      this.$http.post(url).then(
        res => {
          this.isLoading = false;
          this.dataList = res.data.data.list;
          this.pageModal.totalCount = res.data.data.totalCount;
        },
        () => {
          this.isLoading = false;
        }
      );
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageModal.pageNum = val;
      this.getList();
    }
  }
};
</script>

<style lang="scss" scoped>
.page-content-div {
  display: flex;
  justify-content: center;
  width: 100%;
  .page-content {
    width: 1200px;
    margin-top: 10px;
  }
}
.consultation-content {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
  .news-info {
    width: 384px;
    height: 320px;
    border: 1px solid #ccc;
    transition: all 0.6s;
    margin: 14px 6px;
    .img-content {
      height: 204px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    p {
      padding: 10px;
    }
    small {
      padding: 10px 10px 20px 10px;
      color: #666;
    }
  }

  .news-info:hover {
    transform: scale(1.03);
  }
}
.page-title {
  margin-bottom: 0;
}
.pagination-content {
  margin-top: 30px;
  text-align: center;
}
.option-btn {
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
  margin: 10px 0;
}

.news-title {
  display: inline-block;
  white-space: nowrap;
  width: 98%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>